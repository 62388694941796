<template>
    <div class="home-page">
        <div class="content-wrapper">
            <div class="intro-copy">
                <h1>
                    <TypeText text="Hi, I'm a developer." />
                </h1>
                <h2>
                    <TypeText
                        text="I make things on the internet work."
                        startDelay="1000"
                    />
                </h2>
                <p class="subtle align-right fade-in">- so you dont have to!</p>
                <!-- <button class="std-btn">Message Me</button> -->
                <AnimatedButton @click.native="$router.push('/contact')" text="Message Me" delayTime="3000" style="margin-top:3em;"/>
            </div>
        </div>
        <img class="profile-img" src="@/assets/half-profile.png" alt="" />
    </div>
</template>
<script>
// Next steps:
// Background needs some kind of animation on moving the Mouse
// Add live chat connected to pubnub or redirecting to email
// Add portfolio page
// Abilities page could be knock off of disinfection where you need to collect the logos

import AnimatedButton from "@/components/Buttons/AnimatedButton";
import TypeText from "@/components/AnimatedText/TypeText";
export default {
    components: {
        AnimatedButton,
        TypeText,
    },
};
</script>

<style lang="scss" scoped>
@import url("https://fonts.googleapis.com/css2?family=Hammersmith+One&display=swap");

.home-page{
    display:flex;
    justify-content: center;
    align-items: center;
    height: 100%;
}

.content-wrapper {
    width: 90vw;
    max-width: 425px;
    color: #fff;
    z-index:1; // Place on top of image
}

.subtle {
    opacity: 0.8;
    font-size: 0.8em;
}

.align-right {
    text-align: right;
    margin-left: auto;
}

.profile-img {
    opacity:0.5;
    width: 80%;
    max-width: 300px;
    display: block;
    margin-left: auto;
    position: absolute;
    bottom: 0;
    right: 0;
}

.std-btn {
    background: transparent;
    border: 2px solid $primaryColor;
    color: $primaryColor;
    padding: 1em 2em;
    width: 100%;
    cursor: pointer;

    margin-top: 1.2em;
    font-size: 1.2em;

    &:hover {
        background-color: $primaryColor;
        color: #fff;
    }
}

.fade-in {
    opacity: 0;
    animation: fade-in 1s 2.2s forwards;
}

footer {
    position: absolute;
    bottom: 0.2em;
}

@media screen and (min-width: $desktopBreakpoint) {
    .std-btn {
        width: auto;
    }
}

@keyframes fade-in {
    to {
        opacity: 1;
    }
}
</style>
