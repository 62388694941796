<template>
    <div class="contact-page">
        <div class="questions">
            <div class="question">
                <p>
                    <TypeText text="Hello, what is your name?" />
                </p>
                <div class="prompt">
                    <span class="prompt-icon">></span>
                    <input
                        id="name"
                        type="text"
                        name="name"
                        v-model="input.name"
                        @keyup="checkIfComplete"
                        @keydown="redirectTab"
                    />
                </div>
            </div>
            <div class="question" v-if="showPrompt.email">
                <p>
                    <TypeText text="To which email should I respond?" />
                </p>
                <div class="prompt">
                    <span class="prompt-icon">></span>
                    <input
                        id="email"
                        type="text"
                        name="email"
                        v-model="input.email"
                        @keyup="checkIfComplete"
                        @keydown="redirectTab"
                    />
                </div>
            </div>
            <div class="question" v-if="showPrompt.message">
                <p>
                    <TypeText text="How can I help you today?" />
                </p>
                <div class="prompt">
                    <span class="prompt-icon">></span>
                    <textarea
                        id="message"
                        name="message"
                        v-model="input.message"
                        rows="4"
                        @keyup="checkIfComplete"
                        @keydown="redirectTab"
                    ></textarea>
                </div>
            </div>
            <div class="question" v-if="showPrompt.send">
                <AnimatedButton
                    id="send"
                    tabindex="0"
                    @keydown.native="handleEnter"
                    @click.native="sendMessage"
                    text="Send"
                    :class="sent ? 'disable-pointer' : ''"
                    style="margin-top:0.5em;"
                />
            </div>
        </div>
        <div class="status">
            <ul>
                <li>
                    <span class="icon" :class="input.name ? 'done' : ''"></span>
                    <span>
                        Your name
                        <div class="hint" :class="!input.name ? 'active' : ''">
                            Enter your name
                        </div>
                        <div
                            class="hint"
                            :class="
                                input.name && !input.email && !showPrompt.email
                                    ? 'active'
                                    : ''
                            "
                        >
                            Press enter to continue
                        </div>
                    </span>
                </li>
                <li>
                    <span
                        class="icon"
                        :class="input.email ? 'done' : ''"
                    ></span>
                    <span>
                        Your email
                        <div
                            class="hint"
                            :class="
                                input.name && !input.email && showPrompt.email
                                    ? 'active'
                                    : ''
                            "
                        >
                            Enter your email
                        </div>
                        <div
                            class="hint"
                            :class="
                                input.email &&
                                !input.message &&
                                !showPrompt.message
                                    ? 'active'
                                    : ''
                            "
                        >
                            Press enter to continue
                        </div>
                    </span>
                </li>
                <li>
                    <span
                        class="icon"
                        :class="input.message ? 'done' : ''"
                    ></span>
                    <span>
                        Your message
                        <div
                            class="hint"
                            :class="
                                input.email &&
                                !input.message &&
                                showPrompt.message
                                    ? 'active'
                                    : ''
                            "
                        >
                            Write your message
                        </div>
                        <div
                            class="hint"
                            :class="
                                input.message &&
                                !showPrompt.send &&
                                !showPrompt.send
                                    ? 'active'
                                    : ''
                            "
                        >
                            Press enter to continue
                        </div>
                    </span>
                </li>
                <li>
                    <span class="icon" :class="sent ? 'done' : ''"></span>
                    <span>
                        Sent
                        <div
                            class="hint"
                            :class="showPrompt.send && !sent ? 'active' : ''"
                        >
                            Click send to get in touch
                        </div>
                    </span>
                </li>
            </ul>
        </div>
    </div>
</template>
<script>
import TypeText from "@/components/AnimatedText/TypeText";
import AnimatedButton from "@/components/Buttons/AnimatedButton";
export default {
    components: {
        TypeText,
        AnimatedButton,
    },
    data() {
        return {
            input: {
                name: undefined,
                email: undefined,
                message: undefined,
            },
            showPrompt: {
                email: false,
                message: false,
                send: false,
            },
            sent: false,
        };
    },
    mounted() {
        setTimeout(() => {
            this.focusOnInput("name");
        }, 700);
    },
    methods: {
        disableInputs() {
            document.getElementById("name").readOnly = true;
            document.getElementById("email").readOnly = true;
            document.getElementById("message").readOnly = true;
        },
        sendMessage() {
            if (this.sent) return; // Dont allow double send
            this.sent = true;
            document.getElementById("send").blur();
            this.disableInputs();
            this.sendForm();
        },
        redirectTab(e) {
            if (e.which == 9) {
                if (e.target.id == "name" && this.input.name) {
                    e.preventDefault();
                    this.showPrompt.email = true;
                    this.focusOnInput("email");
                } else if (e.target.id == "email" && this.input.email) {
                    e.preventDefault();
                    this.showPrompt.message = true;
                    this.focusOnInput("message");
                    e.preventDefault();
                } else if (e.target.id == "message" && this.input.message) {
                    e.preventDefault();
                    this.showPrompt.send = true;
                    this.focusOnInput("send");
                }
            }
        },
        handleEnter(e) {
            if (e.key == "Enter") {
                this.sendMessage();
            }
        },
        checkIfComplete(e) {
            if (e.key == "Enter") {
                if (e.target.id == "name" && this.input.name) {
                    this.showPrompt.email = true;
                    this.focusOnInput("email");
                } else if (e.target.id == "email" && this.input.email) {
                    this.showPrompt.message = true;
                    this.focusOnInput("message");
                } else if (e.target.id == "message" && this.input.message) {
                    this.showPrompt.send = true;
                }
            }
        },
        focusOnInput(id) {
            setTimeout(() => {
                document.getElementById(id).focus();
            }, 100); // Delay to wait for DOM
        },
        makeHttpRequest(dataString) {
            var req = new XMLHttpRequest();
            req.onreadystatechange = processResponse;
            req.open("POST", "/static/mailer.php", true);
            req.setRequestHeader("X-Requested-With", "XMLHttpRequest");
            req.setRequestHeader(
                "Content-Type",
                "application/x-www-form-urlencoded"
            );
            req.send(dataString);

            function processResponse() {
                if (req.readyState != 4) return; /* State 4 is DONE */
                // document.getElementById("status").innerText = req.responseText;
            }
        },

        sendForm() {
            this.makeHttpRequest(
                "name=" +
                    this.input.name +
                    "&email=" +
                    this.input.email +
                    "&message=" +
                    this.input.message
            );
        },
    },
};
</script>

<style lang="scss" scoped>
.contact-page {
    color: #fff;

    input,
    textarea {
        background-color: transparent;
        border: none;
        outline: none;
        color: #fff;
        width: 100%;
    }

    textarea {
        resize: none;
    }

    .questions {
        height: 365px;
        .question {
            p {
                font-size: 1.5em;
            }
            .prompt {
                display: flex;
            }
            span {
                opacity: 0.7;
            }
            &:focus-within {
                span {
                    animation: flash 2s infinite;
                    visibility: visible;
                }
            }
        }
    }

    .status {
        .hint {
            display: none;
            font-size: 0.7em;
            font-style: italic;
            color: $primaryColor;
            &.active {
                display: block;
            }
        }
        .icon {
            margin-left: 1em;
            font-family: "Font Awesome 5 Free";
            font-weight: 400;
            font-size: 1.4em;

            &.done {
                &:before {
                    content: "\f058";
                    color: $primaryColor;
                }
            }
            &:before {
                content: "\f111";
            }
        }

        ul {
            list-style: none;
            margin: 0;
            padding: 0;

            li {
                display: flex;
                align-items: center;
                margin: 0.2em 0;
                justify-content: flex-start;
                flex-direction: row-reverse;

                text-align: right;
            }
        }
    }

    .disable-pointer {
        pointer-events: none;
    }
}

@media screen and (min-width: $desktopBreakpoint) {
    .contact-page {
        display: flex;
        flex-direction: row-reverse;
        justify-content: center;
        align-items: center;

        height: 100%;

        .questions {
            width: 400px;
            height: 400px;
        }

        .status {
            padding: 3em;
            margin-right: 2em;
            width: 300px;

            .icon {
                margin-left: 0;
                margin-right: 1em;
            }

            ul {
                list-style: none;
                margin: 0;
                padding: 0;

                li {
                    justify-content: stretch;
                    flex-direction: row;
                    margin: 1em 0;
                    text-align: left;
                }
            }
        }
    }
}

@keyframes flash {
    0% {
        opacity: 0.1;
    }
    50% {
        opacity: 1;
    }
    100% {
        opacity: 0.1;
    }
}
</style>
