<template>
    <header>
        <div class="logo">
            <router-link to="/">
                <img
                    class="logo-horizontal"
                    src="@/assets/km-logo-white.svg"
                    alt=""
                />
                <img
                    class="logo-vertical"
                    src="@/assets/km-logo-white-vertical.svg"
                    alt=""
                />
            </router-link>
        </div>
        <div class="nav-menu">
            <ul>
                <li v-for="(item, index) in menu" :key="'item-' + index">
                    <router-link :to="item.path">
                        <span class="icon" v-html="item.icon"></span>
                        <span class="text">{{ item.text }}</span>
                    </router-link>
                </li>
            </ul>
        </div>
        <div class="spacer"></div>
    </header>
</template>
<script>
export default {
    data() {
        return {
            menu: [
                {
                    icon: '<i class="fas fa-home"></i>',
                    text: "Home",
                    path: "/",
                },
                {
                    icon: '<i class="far fa-newspaper"></i>',
                    text: "Work",
                    path: "/portfolio",
                },
                {
                    icon: '<i class="fas fa-brain"></i>',
                    text: "Skills",
                    path: "/skills",
                },
                {
                    icon: '<i class="fas fa-mobile"></i>',
                    text: "Contact",
                    path: "/contact",
                },
            ],
        };
    },
};
</script>

<style lang="scss" scoped>
header {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    height: $menuBoxSize;
}

.logo-horizontal {
    position: fixed;
    top: 1em;
    left: 50%;
    transform: translateX(-50%);
    width: 150px;
}

.logo-vertical {
    display: none; // Hide menu logo on mobile
}

.nav-menu {
    color: #fff;
    cursor: pointer;
    position: relative;
    z-index: 1;

    ul {
        margin: 0;
        padding: 0;
        list-style: none;
        display: flex;
        justify-content: center;
        align-items: center;
        a {
            position: relative;
            color: inherit;
            text-decoration: none;

            &.router-link-exact-active {
                color: $primaryColor;
            }

            span {
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
            }

            .icon {
                transition: 1s;
                font-size: 1.5em;
            }

            .text {
                text-transform: uppercase;
                transition: 0.3s;
                opacity: 0;
            }

            &:hover {
                .icon {
                    transition: 0.3s;
                    opacity: 0;
                }

                .text {
                    transition: 1s;
                    opacity: 1;
                }
            }
        }
        li {
            height: $menuBoxSize;
            width: 100%;
            transition: 0.5s;
            display: flex;
            justify-content: center;
            align-items: center;
        }
    }
}

@media screen and (min-width: $tabletBreakpoint) {
    header {
        top: 0;
        bottom: 0;
        left: 0;
        right: revert;
        display: flex;
        flex-direction: column;
        width: $menuBoxSize;
        height: auto;
    }

    .logo {
        display: flex;
        justify-content: center;
        padding: 3em 0;
        a {
            display: block;
            width: 50%;
        }
        img {
            width: 100%;
        }
    }

    .logo-vertical {
        display: block; // Hide menu logo on mobile
    }

    .logo-horizontal {
        display: none; // Hide menu logo on mobile
    }

    .nav-menu {
        color: #fff;
        cursor: pointer;

        ul {
            flex-direction: column;
        }
    }
}
</style>
