<template>
    <div class="portfolio-page">
        <div class="portfolio-wrapper">
            <div
                v-for="(item, index) in portfolio"
                :key="'portfolio-' + index"
                class="item"
            >
                <PortfolioItem :item="item" />
            </div>
        </div>
        <!-- <splide>
            <splide-slide
                v-for="(work, index) in portfolio"
                :key="'work -' + index"
                class="work"
            >
                {{ work.name }}
            </splide-slide>
        </splide> -->
        <!-- <div class="view-more" style="position:absolute; bottom:1em; left:40px;transform:translateX(-50%);color:#fff;">
            MORE
        </div> -->
    </div>
</template>
<script>
// import { Splide, SplideSlide } from "@splidejs/vue-splide";
// import "@splidejs/splide/dist/css/themes/splide-default.min.css";
import PortfolioItem from "@/components/Pages/PortfolioPage/PortfolioItem";
export default {
    components: {
        // Splide,
        // SplideSlide,
        PortfolioItem,
    },
    data() {
        return {
            portfolio: [
                {
                    name: "Ultralytics",
                    category: "Business",
                    language:
                        '<i class="fab fa-vuejs"></i><i class="fab fa-google"></i>',
                    logo: "/static/img/portfolio/ultralytics-logo.png",
                    cover: "/static/img/portfolio/ultralytics-cover.jpg",
                    style: "background-color:#66b3ff;",
                    link: "https://ultralytics.com/",
                },
                {
                    name: "Vluks 3D",
                    category: "Business",
                    language:
                        '<i class="fab fa-js"></i><i class="fab fa-php"></i>',
                    logo: "/static/img/portfolio/vluks3d-logo.png",
                    cover: "/static/img/portfolio/vluks3d-cover.jpg",
                    style: "background-color:#0e4387;",
                    link: "https://www.vluks3d.com/",
                },
                {
                    name: "Disinfection",
                    category: "Game",
                    language:
                        '<i class="fab fa-html5"></i><i class="fab fa-css3-alt"></i><i class="fab fa-js">',
                    logo: "/static/img/portfolio/disinfection-logo.svg",
                    cover: "/static/img/portfolio/disinfection-cover.png",
                    style: "background-color:#01875e;",
                    link: "https://kalenmichael.com/disinfection",
                },
                {
                    name: "Ramblunt",
                    category: "eCommerce",
                    language:
                        '<i class="fab fa-wordpress"></i><i class="fab fa-js"></i>',
                    logo: "/static/img/portfolio/ramblunt-logo.png",
                    cover: "/static/img/portfolio/ramblunt-cover.jpg",
                    style: "background-color:#00501e;",
                    link: "https://ramblunt.com/",
                },
                {
                    name: "Embutidos Alvic",
                    category: "Business",
                    language: '<i class="fab fa-wordpress"></i>',
                    logo: "/static/img/portfolio/embutidos-alvic-logo.png",
                    cover: "/static/img/portfolio/embutidos-alvic-cover.jpg",
                    style: "background-color:#dd3333;",
                    link: "https://embutidosalvic.com/",
                },
                {
                    name: "Almendras de la Rioja",
                    category: "Business",
                    language: '<i class="fab fa-wordpress"></i>',
                    logo: "/static/img/portfolio/almendras-logo.png",
                    cover: "/static/img/portfolio/almendras-cover.jpg",
                    style: "background-color:#ffc77d;",
                    // link: "https://almendrasdelarioja.es/",
                },
                {
                    name: "Provo Elite",
                    category: "eCommerce",
                    language:
                        '<i class="fab fa-wordpress"></i><i class="fab fa-elementor"></i>',
                    logo: "/static/img/portfolio/provo-elite-logo.png",
                    cover: "/static/img/portfolio/provo-elite-cover.jpg",
                    style: "background-color:#0055ff;",
                    // link: 'https://archives.kalenmichael.com/provo-elite'
                },
                {
                    name: "Sprice App",
                    category: "Web Application",
                    language:
                        '<i class="fab fa-php"></i><i class="fa fa-database"></i>',
                    logo: "/static/img/portfolio/sprice-logo.svg",
                    cover: "/static/img/portfolio/sprice-cover.jpg",
                    style: "background-color:#135958;",
                    // link: "https://kalenmichael.com/sprice",
                },

                {
                    name: "3D Hero",
                    category: "",
                    language:
                        '<i class="fab fa-wordpress"></i><i class="fab fa-elementor"></i>',
                    logo: "/static/img/portfolio/3dhero-logo.png",
                    cover: "/static/img/portfolio/3dhero-cover.svg",
                    style: "background-color:#c0172d;",
                    // link: "https://3dhero.io",
                },

                {
                    name: "Feria de Bodas",
                    category: "Web Application",
                    language:
                        '<i class="fab fa-vuejs"></i><i class="fab fa-laravel"></i>',
                    logo: "/static/img/portfolio/wedding-style-logo.png",
                    cover: "/static/img/portfolio/wedding-style-cover.jpg",
                    style: "background-color:#f8a49b;",
                    // link: "https://feriadebodasvirtual.es/",
                },

                {
                    name: "Suma Business",
                    category: "Landing Page",
                    language:
                        '<i class="fab fa-html5"></i><i class="fab fa-css3-alt"></i><i class="fab fa-js"></i>',
                    logo: "/static/img/portfolio/suma-logo.png",
                    style: "background-color:#525759;",
                },

                {
                    name: "Espacio Nine",
                    category: "eCommerce",
                    language: '<i class="fab fa-wordpress"></i>',
                    logo: "/static/img/portfolio/espacio-nine-logo.svg",
                    style: "background-color:#e85231;",
                },
            ],
        };
    },
};
</script>

<style lang="scss" scoped>
.portfolio-page {
    height: 100%;
    overflow: hidden;
    .work {
        border: 1px solid orange;
        height: 100%;
    }

    .portfolio-wrapper {
        height: 100%;
        display: flex;
        flex-wrap: wrap;
        overflow-y: auto;

        .item {
            display: flex;
            justify-content: center;
            align-items: center;
            flex-grow: 1;
            width: 100%;

            height: 33%;

            // &:nth-child(5), &:nth-child(6), &:nth-child(7){
            //     width:33%;
            // }
        }
    }
}
@media screen and (min-width: $desktopBreakpoint) {
    .portfolio-page {
        justify-content: center;
        align-items: center;
        .portfolio-wrapper {
            .item {
                width: 33%;
            }
        }
    }
}
</style>
