<template>
    <div id="app">
        <div class="page">
            <div class="inner-page">
                <Header />
                <router-view></router-view>
            </div>
        </div>
    </div>
</template>

<script>
import Header from "@/components/Header";
export default {
    name: "App",
    components: {
        Header,
    },
};
</script>

<style lang="scss">
@mixin no-select {
    -ms-user-select: none;
    -moz-user-select: none;
    -webkit-user-select: none;
    -webkit-touch-callout: none;
    -khtml-user-select: none;
    user-select: none;
}

* {
    box-sizing: border-box;
}

body {
    margin: 0;
    height: 100vh;
}

#app {
    width: 100%;
    height: 100%;
}

.page {
    width: 100%;
    height: 100%;
    background-color: $backgroundColor;
}

.inner-page {
    @include no-select;
    padding: $menuBoxSize 1em;
    height: 100%;
    font-family: "Hammersmith One", sans-serif;
}

@media screen and (min-width: $desktopBreakpoint) {
    .inner-page {
        padding: 1em 1em 1em $menuBoxSize;
    }
}

@media screen and (min-width: $maxPageWidth) {
    #app {
        background:linear-gradient(135deg, lighten($backgroundColor, 10%) 55%, $primaryColor) ;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .page {
        max-width: $maxPageWidth;
        max-height: $maxPageHeight;
        position: relative;

        border-radius: 20px;
        overflow: hidden;
        box-shadow: 0px 0px 19px 0px rgba(0, 0, 0, 0.75);
        -webkit-box-shadow: 0px 0px 19px 0px rgba(0, 0, 0, 0.75);
        -moz-box-shadow: 0px 0px 19px 0px rgba(0, 0, 0, 0.75);
    }
}
</style>
