<template>
    <div class="text-wrapper">
        <span
            v-for="(letter, index) in printed"
            :key="'letter-' + index"
            class="letter"
            :data-text="letter"
        >
            {{ letter }}
        </span>
    </div>
</template>
<script>
export default {
    props: ["text", "printDelay", "startDelay"],
    data() {
        return {
            printed: [],
            index: 0,
        };
    },
    mounted() {
        if (this.text) setTimeout(this.printLetter, this.startDelay || 0);
    },
    methods: {
        printLetter() {
            let delay = this.printDelay || 20; // Delay in ms between printing letters
            this.printed.push(this.text[this.index]);
            if (this.index > this.text.length - 1) return; // All letters printed
            this.index++;
            setTimeout(this.printLetter, delay);
        },
    },
};
</script>

<style lang="scss" scoped>
.text-wrapper {
    letter-spacing: -0.24em;
    height: 1em;
    display:flex;
    flex-wrap: wrap;
}
.letter {
    white-space: pre;
    opacity: 0;
    transition: 0.3s;
    animation: fade-in 200ms forwards;
    position: relative;

    &:hover,
    .glitch {
        opacity: 1;

        &:after {
            opacity: 0;
            display: block;
            content: attr(data-text);
            position: absolute;
            top: 0;
            left: -2px;
            text-shadow: -2px 0 #00fff9, 2px 2px #ff00c1;
            animation: glitch 0.5s infinite;
        }

        &:before {
            opacity: 0;
            display: block;
            content: attr(data-text);
            position: absolute;
            top: 0;
            left: 2px;
            text-shadow: -2px 0 #ff00c1;
            animation: glitch 0.5s infinite;
        }
    }

    // Run the animation once on creation
    &:after {
        opacity: 0;
        display: block;
        content: attr(data-text);
        position: absolute;
        top: 0;
        left: -2px;
        text-shadow: -2px 0 #00fff9, 2px 2px #ff00c1;
        animation: glitch 0.3s;
    }

    &:before {
        opacity: 0;
        display: block;
        content: attr(data-text);
        position: absolute;
        top: 0;
        left: 2px;
        text-shadow: -2px 0 #ff00c1;
        animation: glitch 0.3s;
    }
}

@keyframes glitch {
    0% {
        opacity: 0;
    }
    25% {
        opacity: 1;
        transform: skew((random(10) - 5) + deg);
    }
    50% {
        clip: rect(random(100) + px, 9999px, random(100) + px, 0);
        transform: skew((random(10) - 5) + deg);
    }
    75% {
        transform: skew((random(10) - 5) + deg);
    }
    100% {
        opacity: 0;
    }
}

@keyframes fade-in {
    to {
        opacity: 1;
    }
}
</style>
