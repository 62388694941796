import Vue from "vue";
import VueRouter from "vue-router";

Vue.use(VueRouter);

const routes = [
    {
        path: "/",
        name: "home",
        component: require("@/components/Pages/HomePage/index").default,
    },
    {
        path: "/contact",
        name: "contact",
        component: require("@/components/Pages/ContactPage/index").default,
    },
    {
        path: "/skills",
        name: "skills",
        component: require("@/components/Pages/SkillsPage/index").default,
    },
    {
        path: "/portfolio",
        name: "portfolio",
        component: require("@/components/Pages/PortfolioPage/index").default,
    },
];

const router = new VueRouter({
    mode: "history",
    base: process.env.BASE_URL,
    routes,
    // scrollBehavior() {
    //     return { x: 0, y: 0 }; // This is to always scroll back to top of page
    // }
    scrollBehavior(to, from, savedPosition) {
        if (savedPosition) {
            return savedPosition; // On browser back/forward click
        } else if (window.forceSaveScrollPosition) {
            return window.forceSaveScrollPosition; // On standard router-link
        } else {
            return { x: 0, y: 0 };
        }
    },
    saveScrollPosition: true,
});

export default router;
