<template>
    <div class="skills-page">
        <div class="cover"></div>
        <div class="skills">
            <div
                v-for="(skill, index) in skills"
                :key="'skill-' + index"
                class="skill"
            >
                <i :class="skill.icon"></i>
                <div class="text">
                    <div class="title">{{ skill.title }}</div>
                    <div class="content">{{ skill.content }}</div>
                </div>
            </div>
        </div>
        <!-- <div class="social-media">
            <ul>
                <li>
                    <a href="https://github.com/KalenMike">
                        <i class="fab fa-github"></i>
                    </a>
                </li>
                <li>
                    <a href="https://www.instagram.com/kalenmichael/">
                        <i class="fab fa-instagram-square"></i>
                    </a>
                </li>
                <li>
                    <a href="#contact"><i class="fas fa-envelope"></i></a>
                </li>
            </ul>
        </div> -->
    </div>
</template>
<script>
export default {
    data() {
        return {
            skills: [
                {
                    icon: "fab fa-html5",
                    title: "HTML5",
                    content:
                        "Absolutely essential for web development, I have thousands of hours of experience coding html.",
                },
                {
                    icon: "fab fa-css3-alt",
                    title: "CSS3",
                    content:
                        "Long gone are the days of table based websites, CSS is the digital decoration. A must needed skill for any developer.",
                },
                {
                    icon: "fab fa-js",
                    title: "Javascript",
                    content:
                        "Used to bring pages to life, I use vanilla JS or libraries depending on the requirements.",
                },
                {
                    icon: "fab fa-python",
                    title: "Python",
                    content:
                        "I built my first professional application in Python with PyQT, these days I use it mostly for backend work or to create a quick script to tackle a task.",
                },
                {
                    icon: "fa fa-database",
                    title: "MySql",
                    content:
                        "I have many years experience with MySQL, I first got familiar back in 2006 building some Visual Basics 6 applications.",
                },
                {
                    icon: "fab fa-php",
                    title: "Php",
                    content:
                        "I originally learnt php for advanced customisation of WordPress, but eventually outside of the CMS and now I am highly skilled.",
                },
                {
                    icon: "fab fa-vuejs",
                    title: "Vue",
                    content:
                        "Single page apps to the rescue. I love to use Vue when the project requires speed and a app like feel.",
                },
                {
                    icon: "fab fa-laravel",
                    title: "Laravel",
                    content:
                        "One of my favourite frameworks for creating the backend of single page apps or progressive web apps.",
                },
                {
                    icon: "fab fa-npm",
                    title: "Npm",
                    content:
                        "Why reinvent the wheel, if something already exists that provides the features you need I am happy to get it setup to save you time and money.",
                },
                {
                    icon: "fab fa-linux",
                    title: "Linux CLI",
                    content:
                        "My operating system of choice. My daily usage brings familiarity allowing me to quickly resolve issues with your server.",
                },
                {
                    icon: "fab fa-wordpress",
                    title: "WordPress",
                    content:
                        "I often create custom themes/plugins in WordPress for clients who want to have control over future changes to the content of their website.",
                },
                {
                    icon: "fab fa-elementor",
                    title: "Elementor",
                    content:
                        "I use elementor for clients who want to be able to make future design changes without consulting a developer.",
                },
            ],
        };
    },
};
</script>

<style lang="scss" scoped>
.skills-page {
    height: 100%;
    background-image: url("/static/img/wallpaper.jpg");
    position: relative;
    overflow: hidden;
    .cover {
        width: 100%;
        height: 100%;
        background-color: #1e1e1e;
        position: absolute;
        top: 0;
        left: 0;
    }
}

.skills {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    color: #fff;
    background-image: inherit;

    height: 100%;
    overflow-y: auto;
}

.skill {
    padding: 3em;
    max-width: 100%;
    width: 100%;
    margin: 0.5em;
    display: flex;
    align-items: center;
    justify-content: center;
    // cursor: pointer;
    position: relative;
    border: 1px solid transparent;

    background-image: inherit;
    background-attachment: fixed;
    background-size: cover;
    background-position: 100%;

    &:hover {
        transition: border 0.7s;
        border-color: #fff;
        background-image: none;
        background-color: #111111;

        &:before,
        &:after {
            transition: 0.7s;
            opacity: 0;
        }

        &:after {
            bottom: 0;
            right: 0;
        }

        &:before {
            top: 0;
            left: 0;
        }
    }

    &:before,
    &:after {
        content: "";
        position: absolute;
        width: 20px;
        height: 20px;
    }

    &:before {
        border-top: 1px solid #fff;
        border-left: 1px solid #fff;
        top: 0.5em;
        left: 0.5em;
    }
    &:after {
        border-bottom: 1px solid #fff;
        border-right: 1px solid #fff;
        bottom: 0.5em;
        right: 0.5em;
    }

    i {
        transition: 0.3s;
        font-size: 3em;
    }
    .text {
        transition: 0.7s;
        opacity: 0;
        text-align: center;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 90%;

        .title {
            font-size: 1.1em;
        }

        .content {
            font-size:0.8em;
        }
    }

    &:hover {
        .text {
            opacity: 1;
        }

        i {
            opacity: 0;
        }
    }
}

@media screen and (min-width: $desktopBreakpoint) {
    .skills-page {
        display: flex;
        justify-content: center;
        align-items: center;

        .skill {
            max-width: 100%;
            width: calc(33% - 1em);
            background-position: 50%;
        }
    }
}
</style>
