<template>
    <div class="item-container">
        <div
            class="item"
            @click="handleClick"
            :style="item.style + 'background-image:url(' + item.cover + ')'"
        >
            <div class="overlay"></div>

            <div v-if="item.link" class="hint">
                OPEN SITE
            </div>
            <div v-else class="hint">
                OFFLINE
            </div>
            <img :src="item.logo" alt="" />
            <div class="language" v-html="item.language"></div>
        </div>
        <div v-if="showPopup" class="popup">
            <div class="popup-content">
                {{ item.name }}
                <div @click="showPopup = false" class="close">✕</div>
                <p>
                    company breakdown
                    <br />
                    name
                    <br />
                    category
                    <br />
                    write-up
                    <br />
                    challenges
                    <br />
                    date
                    <br />
                    duration
                    <br />
                    live link
                    <br />
                    github
                    <br />
                </p>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    props: ["item"],
    data() {
        return {
            showPopup: false,
        };
    },
    methods: {
        handleClick() {
            // this.showPopup = true
            if (!this.item.link) return;
            window.open(this.item.link);
        },
    },
};
</script>

<style lang="scss" scoped>
.item-container {
    cursor: pointer;
    margin: 0.5em;
    width: 100%;
    height: calc(100% - 1em);
    color: #fff;
    position: relative;

    border: 1px solid transparent;

    $edgeSpacing: 0.5em;

    &:hover {
        transition: border 0.7s;
        border-color: #fff;

        &:before,
        &:after {
            transition: 0.7s;
            opacity: 0;
        }

        &:after {
            bottom: 0;
            right: 0;
        }

        &:before {
            top: 0;
            left: 0;
        }

        .overlay {
            // opacity: 0;
        }

        .hint {
            opacity: 1;
            animation:fade-in 0.7s forwards;
        }

        img {
            opacity: 0;
        }

        .language {
            opacity: 0;
        }
    }

    &:before,
    &:after {
        content: "";
        position: absolute;
        height: 20px;
        width: 20px;
        opacity: 0.5;

        z-index: 2;
    }

    &:before {
        border-left: 1px solid #fff;
        border-top: 1px solid #fff;
        top: $edgeSpacing;
        left: $edgeSpacing;
    }

    &:after {
        border-right: 1px solid #fff;
        border-bottom: 1px solid #fff;
        bottom: $edgeSpacing;
        right: $edgeSpacing;
    }

    .item {
        padding: 1em;
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;

        background-size: cover;
        background-repeat: no-repeat;
        background-position: center;

        position: relative;
        z-index: 1;
    }

    .overlay {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: #000;
        opacity: 0.5;
        z-index: -1;
    }

    .hint {
        opacity: 0;
        position: absolute;
        font-size: 1.5em;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    }

    img {
        width: 50%;
        max-height: 50%;
        flex: 1;
        object-fit: contain;
        transition: 0.3s;
    }

    .language {
        font-size: 2em;
        transition: 0.3s;
        ::v-deep i + i {
            margin-left: 0.5em;
        }
    }

    .popup {
        background-color: rgba(0, 0, 0, 0.5);
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        z-index: $topLevel;
        display: flex;
        justify-content: center;
        align-items: center;

        .popup-content {
            background: red;
            width: 95%;
            height: 90%;
            position: relative;
        }

        .close {
            font-size: 2em;
            position: absolute;
            top: 0.2em;
            right: 0.5em;
        }
    }
}

@keyframes fade-in{
    from{
        opacity:0;
    }
    to{
        opacity:1;
    }
}
</style>
